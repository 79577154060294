import { GroupTypeApi } from 'api-typings/groups/groups';
import { SupportTypeApi } from 'api-typings/support/support';

export type FeatureFlagConfig = {
  [feat in Feature]: boolean;
} & ConfigurableFeatures;

export interface ConfigurableFeatures {
  readonly bidCard:
    | {
        readonly bidCardCountry: boolean;
        readonly bidCardEarnings: boolean;
        readonly bidsSealedPill: boolean;
      }
    | false;
  readonly conciergeSidebar:
    | {
        readonly supportTypes: SupportTypeApi[];
      }
    | false;
  readonly mobileAppDownload:
    | {
        readonly androidUrl: string;
        readonly iosUrl: string;
      }
    | false;
  readonly supportContactDetails?:
    | {
        readonly phoneNumber: string;
        readonly email: string;
      }
    | undefined;
  /** Allows clients and freelancers to make audio calls before the project is awarded, regardless of KYC or verification status */
  readonly preAwardAudioCall: {
    readonly enabledForAllClients: boolean;
    readonly enabledForAllFreelancers: boolean;
  };
  readonly calling: {
    readonly useZoom: boolean;
  };
  /**
   * FIXME: T293232 - We can remove this feature flag and move it to a static array as webapp admin has no `uiConfig` theme.
   **/
  readonly aiGeneratedAutoRecommendedSupportMessages: {
    readonly enabledSupportTypes: readonly SupportTypeApi[];
  };
  readonly bidStatsFreelancerProjectViewPage:
    | {
        readonly minBidsToShow: number;
      }
    | false;
  readonly groupsProjectsTab:
    | {
        allowedGroupTypes: readonly GroupTypeApi[];
      }
    | false;
  readonly signupModalFields:
    | {
        readonly fullname: boolean;
        readonly company: boolean;
        readonly phone: boolean;
      }
    | false;
  readonly directoryAndFollowSettings: {
    readonly allowListing: boolean;
    readonly allowWatch: boolean;
    readonly allowSealPriceOnReview: boolean;
  };
  readonly financesMenu:
    | {
        readonly financialDashboard: boolean;
      }
    | false;
  readonly tasklist:
    | {
        readonly showTasklistsAfterBidAccept: boolean;
      }
    | false;
}

export enum Feature {
  AI_PAGE = 'aiPage',
  MEMBERSHIPS = 'memberships',
  CONTESTS = 'contests',
  CONTEST_POST = 'contestPost',
  LOCAL_JOBS = 'localJobs',
  SHOWCASE = 'showcase',
  SEARCH = 'search',
  SEARCH_CONTESTS = 'searchContests',
  CONTEST_UPGRADES = 'contestUpgrades',
  SEARCH_POOLS = 'searchPools',
  COMMUNITY = 'community',
  REFER_A_CLIENT = 'referAClient',
  GIVE_GET = 'giveGet',
  HOW_IT_WORKS_PAGE = 'howItWorksPage',
  ENTERPRISE_PAGE = 'enterprisePage',
  FIXED_BUDGET = 'fixedBudget',
  ABOUT_FLNLTD_PAGES = 'aboutFlnltdPages',
  PROJECT_MANAGEMENT_PAGE = 'projectManagementPage',
  POLLS = 'polls',
  GAMIFICATION = 'gamification',
  PREFERRED_FREELANCER_PROGRAM = 'preferredFreelancerProgram',
  FILEUPLOAD = 'fileUpload',
  PORTFOLIO = 'portfolio',
  INSURANCES_AND_CERTIFICATES = 'insurancesAndCertificates',
  LEGACY_FREELANCER_PROJECT_VIEW_PAGE = 'legacyFreelancerProjectViewPage',
  VERIFICATIONS = 'verifications',
  /** Used on user profile verifications link */
  RATE_LIMIT_VOLUNTARY_KYC = 'rateLimitVoluntaryKyc',
  PROFILE_HOURLY_RATE = 'profileHourlyRate',
  PROFILE_REPEAT_HIRE_RATE = 'profileRepeatHireRate',
  PROFILE_RECOMMENDATION = 'profileRecommendation',
  PROFILE_SUMMARY_EARNINGS = 'profileSummaryEarnings',
  /** The `/jobs/<skill_seo_url>` page */
  JOBS_SEO_PAGE = 'jobsSeoPage',
  ACCOUNT_BALANCE_WIDGET = 'accountBalanceWidget',
  MENU_ADD_FUNDS = 'menuAddFunds',
  BILLING = 'billing',
  /** Project upgrades blanket feature flag */
  PROJECT_UPGRADES = 'projectUpgrades',
  PROJECT_UPGRADES_AUTO_SELECT_FREE = 'projectUpgradesAutoSelectFree',
  /** Project upgrades specific upgrade feature flag */
  PROJECT_UPGRADES_RECRUITER = 'projectUpgradesRecruiter',
  PROJECT_UPGRADES_FEATURED = 'projectUpgradesFeatured',
  PROJECT_UPGRADES_URGENT = 'projectUpgradesUrgent',
  PROJECT_UPGRADES_SEALED = 'projectUpgradesSealed',
  PROJECT_UPGRADES_PRIVATE = 'projectUpgradesPrivate',
  PROJECT_UPGRADES_FULLTIME = 'projectUpgradesFulltime',
  PROJECT_UPGRADES_NDA = 'projectUpgradesNda',
  PROJECT_UPGRADES_IPAGREEMENT = 'projectUpgradesIpagreement',
  PROJECT_UPGRADES_EXTENDED = 'projectUpgradesExtended',
  PROJECT_UPGRADES_COPILOT = 'projectUpgradesCopilot',
  /** Whether or not project upgrades should be displayed/visible */
  DISPLAY_PROJECT_UPGRADE_TAGS = 'displayProjectUpgradeTags',
  LEGACY_PROFILE_PAGE = 'legacyProfilePage',
  EXAMS = 'exams',
  ANALYTICS = 'analytics',
  INSIGHTS = 'insights',
  EMPLOYER_MILESTONE_MODALS = 'employerMilestoneModals',
  FREELANCER_APPS = 'freelancerApps',
  FREELANCER_VERIFIED = 'freelancerVerified',
  COLLABORATION = 'collaboration',
  DASHBOARD_ACCOUNT_SETUP_SECTION = 'dashboardAccountSetupSection',
  /** Searchable pools in Lists */
  POOL_LISTS = 'poolLists',
  /** navigation  */
  DASHBOARD_FEEDBACK_PAGE = 'dashboardFeedbackPage',
  DISPUTE = 'dispute',
  /** directory search */
  DEPRECATED_SEARCH_PAGES = 'deprecatedSearchPages',
  FREELANCER_RATING = 'freelancerRating',
  SITE_MAP = 'siteMap',
  DESKTOP_APP = 'desktopApp',
  DISPLAY_NAV_USERNAME = 'displayNavUsername',
  DISPLAY_NAV_BALANCE = 'displayNavBalance',
  VIEW_REVIEW = 'viewReview',
  REPOST_PROJECT = 'repostProject',
  BOOKMARKS = 'bookmarks',
  TIPPING = 'tipping',
  CHANGE_EMAIL = 'changeEmail',
  ACTIVITY_VIEWER_TRACKING = 'activityViewerTracking',
  NDA_HIDDEN_DESCRIPTION = 'ndaHiddenDescription',
  BILLING_CODE = 'billingCode',
  MILESTONE_REQUESTS = 'milestoneRequests',
  LOCAL_JOBS_DISCLAIMER = 'localJobsDisclaimer',
  BID_UPGRADES = 'bidUpgrades',
  PROJECT_ENGAGEMENT_METER = 'projectEngagementMeter',
  PAYMENT_SHARE_INVITATION_FLOW = 'paymentShareInvitationFlow',
  PROFILE_SKILLS = 'profileSkills',
  PROFILE_RESUME = 'profileResume',
  GROUPS = 'groups',
  GROUPS_RICH_TEXT_POST = 'groupsRichTextPost',
  GROUPS_WORKSPACE = 'groupWorkspaces',
  HIRE_ME = 'hireMe',
  REHIRE = 'rehire',
  QUOTES = 'quotes',
  SALES_TAX = 'salesTax',
  PASSWORD = 'password',
  PAYMENTS = 'payments',
  TWO_FACTOR_AUTHENTICATION = 'twoFactorAuthentication',
  LOCAL_JOBS_UPDATE_LOCATION = 'localJobsUpdateLocation',
  BID_STATS_EMPLOYER_PROJECT_VIEW_PAGE = 'bidStatsEmployerProjectViewPage',
  BID_STATS_FREELANCER_PROJECT_MANAGEMENT_PAGE = 'bidStatsFreelancerProjectManagementPage',
  BID_ADDITIONAL_FILTERS = 'bidAdditionalFilters',
  /** Short term fix to address budgets. Long term fix plans in T251014 */
  PROJECT_BUDGET = 'projectBudget',
  PROJECT_DELIVER_PERIOD = 'projectDeliverPeriod',
  OPERATING_AREAS = 'operatingAreas',
  VEHICLE_TYPES = 'vehicleTypes',
  BULK_MILESTONE_CREATION = 'bulkMilestoneCreation',
  PAYMENT_APPROVALS = 'paymentApprovals',
  INVOICES = 'invoices',
  ONBOARDING_STEP_USER_EXPERIENCE = 'onboardingStepUserExperience',
  ONBOARDING_STEP_LINKED_ACCOUNTS = 'onboardingStepLinkedAccounts',
  PAYMENT_VERIFICATION = 'paymentVerification',
  USE_EXPRESS_WITHDRAWAL_ONLY = 'useExpressWithdrawalOnly',
  ONBOARDING_STEP_PHONE_VERIFICATION = 'onboardingStepPhoneVerification',
  PARTIAL_MILESTONE_RELEASE = 'partialMilestoneRelease',
  ONBOARDING_STEP_EXPRESS_PROFILE_DETAILS = 'onboardingStepExpressProfileDetails',
  /** User Settings page */
  SECURE_PHONE = 'securePhone',
  DELETE_ACCOUNT = 'deleteAccount',
  CHANGE_CURRENCY = 'changeCurrency',
  CHANGE_TAX_INFORMATION = 'changeTaxInformation',
  /** Individual Emails for User Settings page*/
  ANNOUNCEMENT_NOTIFICATION = 'announcementNotification',
  TOP_BIDDER_NOTIFICATION = 'topBidderNotification',
  FREEMARKET_NOTIFICATION = 'freemarketNotification',
  CONTACT_REQUEST_NOTIFICATION = 'contactRequestNotification',
  /** Other Notification Section for User Settings page*/
  MARKETING_NOTIFICATION = 'marketingNotification',
  DEALS_NOTIFICATION = 'dealsNotification',
  NEWSLETTER_NOTIFICATION = 'newsletterNotification',
  COMMUNITY_DIGEST_NOTIFICATION = 'communityDigestNotification',
  REFERRAL_NOTIFICATION = 'referralNotification',
  SMS_NOTIFICATION = 'smsNotification',
  BID_RANKING = 'bidRanking',
  BID_INSIGHT_WIDGET = 'bidInsightWidget',
  FREE_PLUS_MEMBERSHIP = 'freePlusMembership',
  /** Show upsell if users don't have the benefit */
  CLIENT_ENGAGEMENT_UPSELL = 'clientEngagementUpsell',
  CREDIT_TERMS = 'creditTerms',
  REDIRECT_ONBOARDING_TO_SEARCH = 'redirectOnboardingToSearch',
  SHOW_REPORT_BID = 'showReportBid',
  IN_PROGRESS_PROJECT_EDIT = 'inProgressProjectEdit',
  SHOW_SUGGESTED_MILESTONE = 'showSuggestedMilestone',
  CHARGE_PROJECT_FEE_ON_AWARD = 'chargeProjectFeeOnAward',
  INCLUDE_LOCAL_IN_SKILLS_LIMIT = 'includeLocalInSkillsLimit',
  PAYMENT_SUMMARY_BID_AMOUNT = 'paymentSummaryBidAmount',
  VIDEO_BIDS = 'videoBids',
  USER_AVAILABILITY = 'userAvailability',
  // Pings the service to send user IP on every new session
  SAVE_LAST_ONLINE_PRESENCE = 'saveLastOnlinePresence',
  AI_BID_WRITER = 'aiBidWriter',
  CHAT_REQUEST = 'chatRequest',
  AI_CHAT = 'aiChat',
  OFFSITING_DETAILS_INPUT_VALIDATION = 'offsitingDetailsInputValidation',
  // Additional questions to add details to bids
  BID_QUESTIONS = 'bidQuestions',
  SMART_TEXT_FIELD = 'smartTextField',
  REQUEST_MILESTONE_ON_AWARD = 'requestMilestoneOnAward',
  PROJECT_PUBLIC_CLARIFICATION_BOARD = 'projectPublicClarificationBoard',
  // My Projects page
  PROJECT_PICKUP_DROPOFF_LOCATION = 'projectPickupDropoffLocation',
  SERVICE_OFFERINGS = 'serviceOfferings',
  // This removes the secondary navigation and moves the List link to the account menu
  MOVE_SECONDARY_NAV_LINKS = 'moveSecondaryNavLinks',
  SHOW_FACEBOOK_LOGIN_SIGNUP = 'showFacebookLoginSignup',
  SHOW_NEW_LOGIN_SIGNUP_FLOW = 'showNewLoginSignupFlow',
  // Content and design of welcome newsfeed item for shippers
  WELCOME_NEWSFEED_ITEM_SHIPPER = 'welcomeNewsfeedItemShipper',
  VERIFICATION_WALL = 'verificationWall',
  RETENTION_SCORE = 'retentionScore',
  INVITE_TO_BID = 'inviteToBid',
  SHOW_USER_SETTINGS_NOTIFICATION_CONTROL = 'showUserSettingsNotificationControl',
  GLOBAL_HOTJAR = 'globalHotjar',
  PROJECT_DELETE = 'projectDelete',
  MY_LIST = 'myList',
  NEW_PROFILE_TOGGLE = 'newProfileToggle',
  DARK_MODE = 'darkMode',
  EXTERNALLY_SOURCED_PROFILE = 'externallySourcedProfile',
  STAFF_BADGE = 'staffBadge',
  OLD_PROFILE = 'oldProfile',
}

export enum SiteTheme {
  DEFAULT = 'default',
  DELOITTE = 'deloitte',
  FACEBOOK = 'facebook',
  PMI = 'pmi',
  FREIGHTLANCER = 'freightlancer',
  YARA = 'yara',
  GELATO = 'gelato',
  HP = 'hp',
}

// FIXME: T267853 - Compile static configurations from single-source of truth
const DEFAULT_CONFIGURATION: FeatureFlagConfig = {
  memberships: true,
  contests: true,
  contestPost: true,
  localJobs: true,
  showcase: true,
  search: true,
  searchPools: true,
  searchContests: true,
  contestUpgrades: true,
  community: true,
  referAClient: true,
  giveGet: true,
  howItWorksPage: true,
  aiPage: true,
  enterprisePage: true,
  fixedBudget: false,
  aboutFlnltdPages: true,
  projectManagementPage: true,
  polls: true,
  gamification: true,
  preferredFreelancerProgram: true,
  fileUpload: true,
  portfolio: true,
  legacyFreelancerProjectViewPage: true,
  verifications: true,
  profileHourlyRate: true,
  profileRepeatHireRate: true,
  profileRecommendation: true,
  jobsSeoPage: true,
  accountBalanceWidget: true,
  billing: true,
  projectUpgrades: true,
  projectUpgradesAutoSelectFree: true,
  projectUpgradesRecruiter: true,
  projectUpgradesFeatured: true,
  projectUpgradesUrgent: true,
  projectUpgradesSealed: true,
  projectUpgradesPrivate: true,
  projectUpgradesFulltime: true,
  projectUpgradesNda: true,
  projectUpgradesIpagreement: true,
  projectUpgradesExtended: true,
  projectUpgradesCopilot: true,
  legacyProfilePage: true,
  exams: true,
  analytics: true,
  insights: true,
  employerMilestoneModals: true,
  freelancerApps: true,
  freelancerVerified: true,
  collaboration: true,
  poolLists: false,
  dashboardAccountSetupSection: true,
  dashboardFeedbackPage: true,
  dispute: true,
  deprecatedSearchPages: true,
  freelancerRating: true,
  siteMap: true,
  desktopApp: true,
  displayNavUsername: true,
  displayNavBalance: true,
  viewReview: true,
  repostProject: true,
  bookmarks: true,
  tipping: true,
  changeEmail: true,
  activityViewerTracking: true,
  ndaHiddenDescription: false,
  billingCode: false,
  milestoneRequests: true,
  localJobsDisclaimer: true,
  bidUpgrades: true,
  signupModalFields: {
    fullname: true,
    company: false,
    phone: false,
  },
  directoryAndFollowSettings: {
    allowListing: true,
    allowWatch: true,
    allowSealPriceOnReview: true,
  },
  calling: {
    useZoom: false,
  },
  preAwardAudioCall: {
    enabledForAllClients: false,
    enabledForAllFreelancers: false,
  },
  projectEngagementMeter: true,
  paymentShareInvitationFlow: false,
  profileSkills: true,
  profileResume: true,
  profileSummaryEarnings: true,
  groups: true,
  groupsRichTextPost: false,
  groupWorkspaces: true,
  groupsProjectsTab: {
    allowedGroupTypes: [GroupTypeApi.WORKROOM],
  },
  hireMe: true,
  rehire: true,
  rateLimitVoluntaryKyc: true,
  quotes: true,
  salesTax: false,
  password: true,
  payments: true,
  menuAddFunds: true,
  twoFactorAuthentication: true,
  localJobsUpdateLocation: true,
  bidStatsEmployerProjectViewPage: true,
  bidStatsFreelancerProjectViewPage: {
    minBidsToShow: 0,
  },
  bidStatsFreelancerProjectManagementPage: true,
  bidCard: {
    bidCardCountry: true,
    bidCardEarnings: true,
    bidsSealedPill: true,
  },
  bidAdditionalFilters: true,
  projectBudget: true,
  projectDeliverPeriod: true,
  operatingAreas: false,
  vehicleTypes: false,
  bulkMilestoneCreation: false,
  paymentApprovals: false,
  invoices: true,
  onboardingStepUserExperience: true,
  onboardingStepLinkedAccounts: true,
  paymentVerification: true,
  useExpressWithdrawalOnly: false,
  onboardingStepPhoneVerification: false,
  partialMilestoneRelease: true,
  onboardingStepExpressProfileDetails: false,
  /** Individual Emails */
  announcementNotification: true,
  topBidderNotification: true,
  freemarketNotification: true,
  contactRequestNotification: true,
  // Others Notification Section - Hidden redundant Card for Email in settings page
  marketingNotification: true,
  dealsNotification: true,
  newsletterNotification: true,
  communityDigestNotification: true,
  referralNotification: true,
  smsNotification: true,
  bidRanking: true,
  bidInsightWidget: true,
  freePlusMembership: true,
  clientEngagementUpsell: true,
  creditTerms: false,
  redirectOnboardingToSearch: false,
  showReportBid: true,
  inProgressProjectEdit: false,
  showSuggestedMilestone: true,
  displayProjectUpgradeTags: true,
  chargeProjectFeeOnAward: true,
  insurancesAndCertificates: false,
  includeLocalInSkillsLimit: true,
  paymentSummaryBidAmount: true,
  videoBids: true,
  userAvailability: false,
  saveLastOnlinePresence: false,
  aiBidWriter: true,
  chatRequest: true,
  mobileAppDownload: {
    androidUrl:
      'https://play.google.com/store/apps/details?id=com.freelancer.android.messenger',
    iosUrl: 'https://apps.apple.com/app/id927810012',
  },
  conciergeSidebar: false,
  aiChat: true,
  offsitingDetailsInputValidation: false,
  bidQuestions: false,
  smartTextField: true,
  requestMilestoneOnAward: true,
  projectPublicClarificationBoard: true,
  aiGeneratedAutoRecommendedSupportMessages: {
    enabledSupportTypes: [
      SupportTypeApi.RECRUITER,
      SupportTypeApi.MEMBERSHIP_CURATION,
      SupportTypeApi.PROJECT_MANAGEMENT,
      SupportTypeApi.FREIGHTLANCER_CONCIERGE,
      SupportTypeApi.VERIFIED_SALES,
      SupportTypeApi.AI_FLOWS,
      SupportTypeApi.AI_ESCALATIONS,
      SupportTypeApi.SERVICES_PROMOTION,
      SupportTypeApi.CLIENT_RESURRECTION_A,
      SupportTypeApi.CLIENT_RESURRECTION_B,
      SupportTypeApi.CLIENT_RESURRECTION_C,
      SupportTypeApi.CLIENT_RESURRECTION_D,
      SupportTypeApi.TIER_ONE_SUPPORT,
      SupportTypeApi.BALANCE_COLLECTOR,
    ],
  },
  tasklist: {
    showTasklistsAfterBidAccept: false,
  },
  projectPickupDropoffLocation: false,
  serviceOfferings: true,
  moveSecondaryNavLinks: false,
  showFacebookLoginSignup: true,
  showNewLoginSignupFlow: true,
  welcomeNewsfeedItemShipper: false,
  verificationWall: true,
  retentionScore: true,
  inviteToBid: true,
  showUserSettingsNotificationControl: true,
  financesMenu: {
    financialDashboard: true,
  },
  globalHotjar: false,
  projectDelete: true,
  myList: true,
  newProfileToggle: true,
  darkMode: true,
  securePhone: true,
  deleteAccount: true,
  changeCurrency: true,
  changeTaxInformation: true,
  externallySourcedProfile: false,
  staffBadge: true,
  oldProfile: false,
} as const;

export const featureFlagConfigurations: {
  [siteName in SiteTheme]: FeatureFlagConfig;
} = {
  default: DEFAULT_CONFIGURATION,

  facebook: {
    ...DEFAULT_CONFIGURATION,
    aiChat: false,
    requestMilestoneOnAward: false,
    projectPublicClarificationBoard: false,
    signupModalFields: {
      fullname: false,
      company: false,
      phone: false,
    },
    verificationWall: false,
    groupsProjectsTab: false,
    darkMode: false,
    staffBadge: false,
  },

  deloitte: {
    ...DEFAULT_CONFIGURATION,
    aiPage: false,
    memberships: false,
    contests: false,
    contestPost: false,
    contestUpgrades: false,
    localJobs: false,
    showcase: false,
    searchContests: false,
    community: false,
    referAClient: false,
    giveGet: false,
    enterprisePage: false,
    projectEngagementMeter: false,
    projectManagementPage: false,
    polls: false,
    gamification: false,
    preferredFreelancerProgram: false,
    fileUpload: false,
    legacyFreelancerProjectViewPage: false,
    verifications: false,
    profileHourlyRate: false,
    jobsSeoPage: false,
    accountBalanceWidget: false,
    billing: false,
    projectUpgrades: false,
    legacyProfilePage: false,
    exams: false,
    insights: false,
    employerMilestoneModals: false,
    freelancerApps: false,
    freelancerVerified: false,
    dashboardAccountSetupSection: false,
    dashboardFeedbackPage: false,
    dispute: false,
    deprecatedSearchPages: false,
    freelancerRating: false,
    siteMap: false,
    desktopApp: false,
    displayNavUsername: false,
    displayNavBalance: false,
    viewReview: false,
    repostProject: false,
    bookmarks: false,
    tipping: false,
    activityViewerTracking: false,
    ndaHiddenDescription: false,
    signupModalFields: {
      fullname: false,
      company: false,
      phone: false,
    },
    directoryAndFollowSettings: {
      allowListing: true,
      allowWatch: false,
      allowSealPriceOnReview: false,
    },
    preAwardAudioCall: {
      enabledForAllClients: true,
      enabledForAllFreelancers: false,
    },
    quotes: false,
    changeEmail: false,
    password: false,
    twoFactorAuthentication: false,
    poolLists: true,
    localJobsUpdateLocation: false,
    bulkMilestoneCreation: true,
    bidStatsEmployerProjectViewPage: false,
    bidStatsFreelancerProjectViewPage: false,
    paymentApprovals: true,
    invoices: false,
    partialMilestoneRelease: false,
    clientEngagementUpsell: false,
    inProgressProjectEdit: true,
    displayProjectUpgradeTags: false,
    paymentSummaryBidAmount: false,
    videoBids: false,
    aiBidWriter: false,
    chatRequest: false,
    mobileAppDownload: false,
    aiChat: false,
    smartTextField: false,
    requestMilestoneOnAward: false,
    projectPublicClarificationBoard: false,
    groupsRichTextPost: true,
    groupsProjectsTab: {
      allowedGroupTypes: [GroupTypeApi.COMMUNITY, GroupTypeApi.WORKROOM],
    },
    serviceOfferings: false,
    showFacebookLoginSignup: false,
    showNewLoginSignupFlow: false,
    verificationWall: false,
    retentionScore: false,
    financesMenu: false,
    newProfileToggle: false,
    darkMode: false,
    // User Settings Others Notifications
    communityDigestNotification: false,
    dealsNotification: false,
    marketingNotification: false,
    newsletterNotification: false,
    referralNotification: false,
    /** User Settings Individual Emails */
    announcementNotification: false,
    topBidderNotification: false,
    freemarketNotification: false,
    contactRequestNotification: false,
    smsNotification: false,
    securePhone: false,
    deleteAccount: false,
    changeCurrency: false,
    changeTaxInformation: false,
    externallySourcedProfile: true,
    staffBadge: false,
    oldProfile: true,
  },

  pmi: {
    ...DEFAULT_CONFIGURATION,
    billingCode: true,
    groups: false,
    chargeProjectFeeOnAward: false,
    mobileAppDownload: false,
    aiChat: false,
    requestMilestoneOnAward: false,
    projectPublicClarificationBoard: false,
    signupModalFields: {
      fullname: false,
      company: false,
      phone: false,
    },
    verificationWall: false,
    groupsProjectsTab: false,
    darkMode: false,
    staffBadge: false,
  },

  yara: {
    ...DEFAULT_CONFIGURATION,
    billingCode: true,
    groups: false,
    chargeProjectFeeOnAward: false,
    mobileAppDownload: false,
    aiChat: false,
    requestMilestoneOnAward: false,
    projectPublicClarificationBoard: false,
    signupModalFields: {
      fullname: false,
      company: false,
      phone: false,
    },
    verificationWall: false,
    groupsProjectsTab: false,
    darkMode: false,
    staffBadge: false,
  },

  freightlancer: {
    ...DEFAULT_CONFIGURATION,
    aiPage: false,
    analytics: false,
    bidUpgrades: false,
    fixedBudget: true,
    giveGet: false,
    contests: false,
    insights: false,
    dashboardAccountSetupSection: false,
    howItWorksPage: false,
    localJobsDisclaimer: false,
    signupModalFields: {
      fullname: true,
      company: true,
      phone: false,
    },
    directoryAndFollowSettings: {
      allowListing: false,
      allowWatch: false,
      allowSealPriceOnReview: true,
    },
    milestoneRequests: false,
    polls: false,
    projectUpgrades: false,
    projectEngagementMeter: false,
    freelancerApps: false,
    deprecatedSearchPages: false,
    exams: false,
    portfolio: false,
    preferredFreelancerProgram: false,
    showcase: false,
    profileSkills: true,
    profileResume: false,
    profileHourlyRate: false,
    profileRepeatHireRate: false,
    profileRecommendation: false,
    profileSummaryEarnings: false,
    groups: true,
    groupWorkspaces: false,
    hireMe: false,
    rehire: false,
    rateLimitVoluntaryKyc: false,
    quotes: false,
    salesTax: true,
    localJobsUpdateLocation: false,
    bidStatsFreelancerProjectViewPage: {
      minBidsToShow: 3,
    },
    bidStatsFreelancerProjectManagementPage: false,
    bidAdditionalFilters: false,
    projectBudget: false,
    projectDeliverPeriod: false,
    operatingAreas: true,
    vehicleTypes: true,
    onboardingStepUserExperience: false,
    onboardingStepLinkedAccounts: false,
    paymentVerification: false,
    useExpressWithdrawalOnly: true,
    gamification: false,
    referAClient: false,
    onboardingStepPhoneVerification: true,
    onboardingStepExpressProfileDetails: true,
    globalHotjar: true,
    menuAddFunds: false,
    /** Individual Emails */
    freemarketNotification: false,
    // Others Notification Section - Hidden redundant Card for Email in settings page
    marketingNotification: false,
    dealsNotification: false,
    newsletterNotification: false,
    communityDigestNotification: false,
    referralNotification: false,
    bidCard: {
      bidCardCountry: false,
      bidCardEarnings: false,
      bidsSealedPill: false,
    },
    bidRanking: false,
    bidInsightWidget: false,
    freePlusMembership: false,
    clientEngagementUpsell: false,
    redirectOnboardingToSearch: true,
    showReportBid: false,
    showSuggestedMilestone: false,
    conciergeSidebar: {
      supportTypes: [
        SupportTypeApi.FREIGHTLANCER_CONCIERGE,
        SupportTypeApi.LOADSHIFT_MARKETPLACE_QUEUE,
      ],
    },
    supportContactDetails: {
      phoneNumber: '1300-562-374',
      email: 'support@loadshift.com.au',
    },
    chargeProjectFeeOnAward: false,
    insurancesAndCertificates: true,
    includeLocalInSkillsLimit: false,
    videoBids: false,
    creditTerms: true,
    userAvailability: true,
    saveLastOnlinePresence: true,
    aiBidWriter: false,
    chatRequest: false,
    mobileAppDownload: {
      androidUrl:
        'https://play.google.com/store/apps/details?id=au.com.loadshift.app',
      iosUrl: 'https://apps.apple.com/au/app/loadshift/id1509592348',
    },
    aiChat: false,
    offsitingDetailsInputValidation: true,
    bidQuestions: true,
    calling: {
      useZoom: true,
    },
    preAwardAudioCall: {
      enabledForAllClients: true,
      enabledForAllFreelancers: true,
    },
    requestMilestoneOnAward: false,
    projectPublicClarificationBoard: false,
    aiGeneratedAutoRecommendedSupportMessages: {
      enabledSupportTypes: [
        SupportTypeApi.FREIGHTLANCER_ASSIGNED_SALES_QUEUE,
        SupportTypeApi.FREIGHTLANCER_CONCIERGE,
        SupportTypeApi.LOADSHIFT_MARKETPLACE_QUEUE,
      ],
    },
    projectPickupDropoffLocation: true,
    serviceOfferings: false,
    accountBalanceWidget: false,
    moveSecondaryNavLinks: true,
    showFacebookLoginSignup: false,
    showNewLoginSignupFlow: false,
    smartTextField: false,
    welcomeNewsfeedItemShipper: true,
    retentionScore: false,
    inviteToBid: false,
    showUserSettingsNotificationControl: false,
    financesMenu: {
      financialDashboard: false,
    },
    tasklist: {
      showTasklistsAfterBidAccept: true,
    },
    projectDelete: false,
    myList: false,
    newProfileToggle: false,
    groupsProjectsTab: false,
    darkMode: false,
    staffBadge: false,
    oldProfile: true,
  },

  gelato: {
    ...DEFAULT_CONFIGURATION,
    billingCode: true,
    chargeProjectFeeOnAward: false,
    mobileAppDownload: false,
    aiChat: false,
    requestMilestoneOnAward: false,
    projectPublicClarificationBoard: false,
    signupModalFields: {
      fullname: false,
      company: false,
      phone: false,
    },
    verificationWall: false,
    groupsProjectsTab: false,
    darkMode: false,
    staffBadge: false,
  },

  hp: {
    ...DEFAULT_CONFIGURATION,
    verificationWall: false,
    groupsProjectsTab: false,
    projectBudget: false,
    darkMode: false,
    staffBadge: false,
  },
};

export const BLACKLISTED_IOS_FEATURES: readonly Feature[] = [
  Feature.MEMBERSHIPS,
  Feature.PROJECT_UPGRADES_AUTO_SELECT_FREE,
  Feature.PROJECT_UPGRADES_FEATURED,
  Feature.PROJECT_UPGRADES_URGENT,
  Feature.PROJECT_UPGRADES_SEALED,
  Feature.PROJECT_UPGRADES_PRIVATE,
  Feature.PROJECT_UPGRADES_FULLTIME,
  Feature.PROJECT_UPGRADES_NDA,
  Feature.PROJECT_UPGRADES_IPAGREEMENT,
  Feature.PROJECT_UPGRADES_EXTENDED,
  Feature.BID_UPGRADES,
  Feature.CONTEST_UPGRADES,
];
